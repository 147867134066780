<template>
    <div class="GoodsEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                ref="member"
                label-width="120px"
                :model="member"
                @submit.native.prevent
                size="small"
                :rules="rules"
            >
                <el-form-item label="姓名">
                    <el-input v-model="member.name" />
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input v-model="member.mobile" />
                </el-form-item>
                <el-form-item label="性别">
                    <el-select v-model="member.sex" style="width: 200px">
                        <el-option label="男" :value="0" />
                        <el-option label="女" :value="1" />
                    </el-select>
                </el-form-item>
                <el-form-item label="年龄">
                    <el-input v-model.number="member.age" />
                </el-form-item>
                <el-form-item label="生日">
                    <el-date-picker
                        v-model="member.birthday"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions"
                    />
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input :readonly="validIdNumber(member.dbNowIdNumber)" v-model="member.idNumber" />
                </el-form-item>
                <!-- <el-form-item label="推荐人" width="120">
                    <el-select v-model="member.developMember" size="mini">
                        <el-option value="" label="请选择"/>
                        <el-option v-for="staff in meta.staffs" :value="staff.code" :key="staff.code"
                                   :label="staff.realName"/>
                    </el-select>
                </el-form-item>-->
                <el-form-item label="省份">
                    <el-select
                        v-model="member.provinceCode"
                        change="member.cityCode=''"
                        style="width: 200px"
                        @change="queryArea(2, member.provinceCode, 2)"
                    >
                        <el-option label="请选择" value="" />
                        <el-option v-for="bd in meta.provinces" :label="bd.name" :value="bd.code" :key="bd.code" />
                    </el-select>
                </el-form-item>
                <el-form-item label="地市">
                    <el-select
                        placeholder="地市"
                        v-model="member.cityCode"
                        onchange="member.districtCode=''"
                        style="width: 200px"
                        @change="queryArea(3, member.cityCode, 3)"
                    >
                        <el-option label="请选择" value="" />
                        <el-option v-for="bd in meta.cites" :label="bd.name" :value="bd.code" :key="bd.code" />
                    </el-select>
                </el-form-item>
                <el-form-item label="区县">
                    <el-select placeholder="区县" v-model="member.districtCode" style="width: 200px">
                        <el-option label="请选择" value="" />
                        <el-option v-for="bd in meta.districts" :label="bd.name" :value="bd.code" :key="bd.code" />
                    </el-select>
                </el-form-item>
                <el-form-item label="详细地址">
                    <el-input v-model="member.address" />
                </el-form-item>
                <el-form-item label="所属机构">
                    <el-select v-model="member.belongDept" filterable :clearable="true">
                        <el-option value="" label="请选择" />
                        <el-option
                            v-for="dept in meta.deptCodes"
                            :label="dept.name"
                            :key="dept.code"
                            :value="dept.code"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="注册机构">
                    <el-input readonly v-model="member.regDeptName" />
                </el-form-item>
                <el-form-item label="注册渠道">
                    <el-input readonly :value="regChannelName()" />
                </el-form-item>
                <el-form-item prop="rst" />
                <el-form-item>
                    <el-button type="primary" @click="handleSave">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import Utils from '../../../../js/Util';
import { mobileValidateRule } from 'js/validate/ValidateCommonRule';
import Util from 'js/Util';

export default {
    name: 'MemberEdit',
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            pickerOptions: {
                disabledDate: (time) => {
                    //不能大于当前日期
                    return time.getTime() > Date.now();
                },
            },
            member: {
                code: '',
                name: '',
                deptCode: '',
                belongDept: '',
                mobile: '',
                sex: null,
                age: null,
                birthday: '',
                provinceCode: '',
                cityCode: '',
                districtCode: '',
                address: '',
                regChannel: null,
                regDeptType: null,
                regDeptName: '',
                idNumber: '',
                dbNowIdNumber: '',
                developMember: '',
                deptGroupCode: '',
            },
            meta: {
                deptGroup: [],
                provinces: [],
                cites: [],
                districts: [],
                types: [],
                staffs: [],
            },
            url: {
                queryArea: '/system/area/list',
                save: '/member/memberEdit/',
                page: '/member/memberAllDetail/',
                queryRelatedStaff: '/system/staff/relatedStaff',
            },
            rules: {
                mobile: mobileValidateRule(),
            },
        };
    },
    mounted() {
        this.handleQuery();
        this.$efApi.deptApi.managedAllDepts().then((rst) => {
            this.meta.deptCodes = rst || [];
        });
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.deptGroup = rst || [];
        });
    },
    methods: {
        validIdNumber(idNumber) {
            if (!idNumber) {
                return false;
            } else {
                if (!window.ef.valid.memberIdNumber(idNumber, true)) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        handleQuery() {
            UrlUtils.QueryRemote(this, this.url.page + this.code, (data) => {
                this.member.code = data.code;
                this.member.name = data.name;
                this.member.deptCode = data.deptCode;
                this.member.mobile = data.mobile;
                this.member.sex = data.sex;
                this.member.age = data.age;
                this.member.birthday = data.birthday ? this.formatDate(new Date(data.birthday)) : null;
                this.member.provinceCode = data.provinceCode;
                this.member.cityCode = data.cityCode;
                this.member.districtCode = data.districtCode;
                this.member.address = data.address;
                this.member.regChannel = data.regChannel;
                this.member.regDeptType = data.regDeptType;
                this.member.regDeptName = data.regDeptName;
                this.member.idNumber = data.idNumber;
                this.member.dbNowIdNumber = data.idNumber;
                this.member.developMember = data.developMember;
                this.member.deptGroupCode = data.deptGroupCode;
                this.member.belongDept = data.belongDept;
                this.queryArea(1);
                if (this.member.provinceCode) {
                    this.queryArea(2, this.member.provinceCode);
                }
                if (this.member.cityCode) {
                    this.queryArea(3, this.member.cityCode);
                }
                // this.queryArea(2, this.member.provinceCode);
                // this.queryArea(3, this.member.cityCode);
                this.queryRelatedStaff();
            });
        },
        queryArea(level, parentCode, vaild) {
            const _this = this;
            if (typeof vaild != 'undefined' && vaild == 2) {
                _this.member.cityCode = '';
                _this.member.districtCode = '';
                _this.member.address = '';
            } else if (typeof vaild != 'undefined' && vaild == 3) {
                _this.member.districtCode = '';
                _this.member.address = '';
            }
            const _params = { params: { level: level, parentCode: parentCode } };
            Utils.queryTable(_this, _this.url.queryArea, _params, (data) => {
                if (level == 1) {
                    _this.meta.provinces = data.data;
                } else if (level == 2) {
                    this.meta.cites = data.data;
                } else if (level == 3) {
                    this.meta.districts = data.data;
                }
            });
        },
        handleSave() {
            this.$refs.member.validate((valid) => {
                if (!valid) {
                    return false;
                }
                const _params = {
                    name: this.member.name,
                    mobile: this.member.mobile,
                    sex: this.member.sex,
                    age: this.member.age,
                    birthday: this.member.birthday,
                    provinceCode: this.member.provinceCode,
                    cityCode: this.member.cityCode,
                    districtCode: this.member.districtCode,
                    address: this.member.address,
                    developMember: this.member.developMember,
                    belongDept: this.member.belongDept,
                    idNumber: this.member.idNumber,
                };
                if (!_params.name) {
                    this.$alert('请填写姓名', '提示', { type: 'warning' });
                    return;
                }
                if (!_params.mobile) {
                    this.$alert('请填写手机号', '提示', { type: 'warning' });
                    return;
                }

                if (_params.idNumber && !window.ef.valid.memberIdNumber(_params.idNumber, true)) {
                    this.$alert('身份证号长度或格式错误,最后的X必须大写', '提示', { type: 'warning' });
                    return;
                }

                UrlUtils.PatchRemote(this, this.url.save + this.member.code, _params, null, () => {
                    this.$message.success('保存成功');
                    this.goBackAndReload();
                });
            });
        },
        regChannelName() {
            return this.member.regChannel === 8 ? '后台新建' : '会员初始化';
        },
        regDeptTypeName() {
            if (this.member.regDeptType === 1) {
                return '总部';
            } else if (this.member.regDeptType === 2) {
                return '配送中心';
            } else {
                return '门店';
            }
        },
        queryRelatedStaff() {
            const params = { params: { deptCode: this.member.deptCode } };
            Util.queryTable(this, this.url.queryRelatedStaff, params, (data) => {
                if (this.$store.state.session.name !== 'admin') {
                    this.meta.staffs = data.data.filter((value) => {
                        return value.username !== 'admin';
                    });
                } else {
                    this.meta.staffs = data.data;
                }
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
